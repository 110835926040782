import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import CardSlider from './CardSlider';
import ResourceCard from './ResourceCard';
import {
  getCollectionLocaleFields,
  getSingleLocaleFields,
  buildUrl,
} from '../utils';

const StyledFeaturedNews = styled(CardSlider)``;

const FeaturedNews = ({ heading, link, locale }) => {
  const { allDatoCmsNews, allDatoCmsNewsArchive } = useStaticQuery(graphql`
    query FeaturedNewsQuery {
      allDatoCmsNews(
        sort: {
          fields: [publishedDate, meta___firstPublishedAt]
          order: [DESC, DESC]
        }
      ) {
        group(field: locale, limit: 8) {
          fieldValue
          nodes {
            ...NewsResourceCardFragment
          }
        }
      }
      allDatoCmsNewsArchive {
        nodes {
          locale
          slug
        }
      }
    }
  `);

  const { nodes } = getCollectionLocaleFields(allDatoCmsNews, locale);

  const { slug: archiveSlug } = getSingleLocaleFields(
    allDatoCmsNewsArchive,
    locale
  );

  const items = nodes;

  const hasItems = items.find(item => item.slug);

  return (
    hasItems && (
      <StyledFeaturedNews heading={heading} link={link}>
        {items.map(item => {
          if (item.slug) {
            const {
              id,
              banner: { image },
              internal: { type },
              publishedDate,
              meta,
              title,
              slug,
            } = item;

            return (
              <ResourceCard
                key={id}
                image={image}
                type={type}
                publishedDate={publishedDate || (meta && meta.firstPublishedAt)}
                heading={title}
                slug={buildUrl(`${archiveSlug}/${slug}`, {
                  locale: locale,
                })}
              />
            );
          } else {
            return '';
          }
        })}
      </StyledFeaturedNews>
    )
  );
};

export default FeaturedNews;
