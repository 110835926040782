import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import CardSlider from './CardSlider';
import ResourceCard from './ResourceCard';
import {
  getCollectionLocaleFields,
  getSingleLocaleFields,
  buildUrl,
} from '../utils';

const StyledFeaturedCaseStudies = styled(CardSlider)``;

const FeaturedCaseStudies = ({ heading, link, items, locale }) => {
  const { allDatoCmsCaseStudy, allDatoCmsCaseStudiesArchive } =
    useStaticQuery(graphql`
      query FeaturedCaseStudiesQuery {
        allDatoCmsCaseStudy(sort: { fields: position, order: ASC }) {
          group(field: locale, limit: 8) {
            fieldValue
            nodes {
              ...CaseStudyResourceCardFragment
            }
          }
        }
        allDatoCmsCaseStudiesArchive {
          nodes {
            locale
            slug
          }
        }
      }
    `);

  const { nodes } = getCollectionLocaleFields(allDatoCmsCaseStudy, locale);

  const { slug: archiveSlug } = getSingleLocaleFields(
    allDatoCmsCaseStudiesArchive,
    locale
  );

  if (!items) {
    items = nodes;
  }

  const hasItems = items.find(item => item.slug);

  return (
    hasItems && (
      <StyledFeaturedCaseStudies heading={heading} link={link}>
        {items.map(item => {
          if (item.slug) {
            const {
              id,
              banner: { image },
              internal: { type },
              title,
              slug,
            } = item;

            return (
              <ResourceCard
                key={id}
                image={image}
                type={type}
                heading={title}
                slug={buildUrl(`${archiveSlug}/${slug}`, {
                  locale: locale,
                })}
              />
            );
          } else {
            return '';
          }
        })}
      </StyledFeaturedCaseStudies>
    )
  );
};

export default FeaturedCaseStudies;
