import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import FeaturedCaseStudies from '../components/FeaturedCaseStudies';
import FeaturedNews from '../components/FeaturedNews';
import FeaturedBlogPosts from '../components/FeaturedBlogPosts';
import ModularBlocks from '../components/ModularBlocks';
import { buildUrl } from '../utils';

const ResourcesArchiveTemplate = ({
  data: {
    datoCmsResourcesArchive,
    datoCmsCaseStudiesArchive,
    datoCmsNewsArchive,
    datoCmsBlogArchive,
  },
  pageContext: { locale },
}) => {
  const { seoMetaTags, slugLocales, title, banner, modularBlocks } =
    datoCmsResourcesArchive;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsResourcesArchive}
    >
      <main>
        <Banner
          heading={title}
          text={banner.text}
          link={banner.link}
          image={banner.image}
        />
        <FeaturedCaseStudies
          heading={datoCmsCaseStudiesArchive.title}
          link={{
            text: datoCmsCaseStudiesArchive.title,
            page: buildUrl(datoCmsCaseStudiesArchive.slug, {
              locale: locale,
            }),
          }}
          locale={locale}
        />
        <FeaturedNews
          heading={datoCmsNewsArchive.title}
          link={{
            text: datoCmsNewsArchive.title,
            page: buildUrl(datoCmsNewsArchive.slug, {
              locale: locale,
            }),
          }}
          locale={locale}
        />
        <FeaturedBlogPosts
          heading={datoCmsBlogArchive.title}
          link={{
            text: datoCmsBlogArchive.title,
            page: buildUrl(datoCmsBlogArchive.slug, {
              locale: locale,
            }),
          }}
          locale={locale}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const ResourcesArchiveTemplateQuery = graphql`
  query ResourcesArchiveTemplateQuery($id: String!, $locale: String!) {
    datoCmsResourcesArchive(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      banner {
        ...BannerFragment
      }
      modularBlocks {
        ...ContentModularBlockFragment
      }
      ...LinkFragment
    }
    datoCmsCaseStudiesArchive(locale: { eq: $locale }) {
      title
      slug
    }
    datoCmsNewsArchive(locale: { eq: $locale }) {
      title
      slug
    }
    datoCmsBlogArchive(locale: { eq: $locale }) {
      title
      slug
    }
  }
`;

export default ResourcesArchiveTemplate;
