import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import CardSlider from './CardSlider';
import ResourceCard from './ResourceCard';
import {
  getCollectionLocaleFields,
  getSingleLocaleFields,
  buildUrl,
} from '../utils';

const StyledFeaturedBlogPosts = styled(CardSlider)``;

const FeaturedBlogPosts = ({ heading, link, items, locale }) => {
  const { allDatoCmsBlog, allDatoCmsBlogArchive } = useStaticQuery(graphql`
    query FeaturedBlogPostsQuery {
      allDatoCmsBlog(
        sort: {
          fields: [publishedDate, meta___firstPublishedAt]
          order: [DESC, DESC]
        }
      ) {
        group(field: locale, limit: 8) {
          fieldValue
          nodes {
            ...BlogResourceCardFragment
          }
        }
      }
      allDatoCmsBlogArchive {
        nodes {
          locale
          slug
        }
      }
    }
  `);

  const { nodes } = getCollectionLocaleFields(allDatoCmsBlog, locale);

  const { slug: archiveSlug } = getSingleLocaleFields(
    allDatoCmsBlogArchive,
    locale
  );

  if (!items) {
    items = nodes;
  }

  const hasItems = items.find(item => item.slug);

  return (
    hasItems && (
      <StyledFeaturedBlogPosts heading={heading} link={link}>
        {items.map(item => {
          if (item.slug) {
            const {
              id,
              banner: { image },
              internal: { type },
              publishedDate,
              meta,
              title,
              slug,
            } = item;

            return (
              <ResourceCard
                key={id}
                image={image}
                type={type}
                publishedDate={publishedDate || (meta && meta.firstPublishedAt)}
                heading={title}
                slug={buildUrl(`${archiveSlug}/${slug}`, {
                  locale: locale,
                })}
              />
            );
          } else {
            return '';
          }
        })}
      </StyledFeaturedBlogPosts>
    )
  );
};

export default FeaturedBlogPosts;
